import { BrowserRouter as Router, Route, Routes, Link, Redirect, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// APP
import Login from "./pages/auth/Login";
import Home from './pages/Home';
import Register from './pages/auth/Register';
import User from './pages/User';
import Explore from './pages/Explore';
import Course from './pages/Course';
import Landing from './pages/Landing';
import Error from './pages/Error';
// WEB
import LoginGuru from './web/pages/LoginGuru';
import RegisterGuru from './web/pages/RegisterGuru';
import Dashboard from './web/pages/Dashboard';
import Profil from './web/pages/Profil';


import './index.css'
import './toast.css'

function App() {

  return (
    <>
      <Routes>
        {/* APP */}
        <Route path='/' Component={Landing} />
        <Route path='/login' Component={Login} />
        <Route path='/register' Component={Register} />
        <Route path='/home' Component={Home} />
        <Route path='/user' Component={User} />
        <Route path='/explore' Component={Explore} />
        <Route path='/course' Component={Course} />
        <Route path='/error' Component={Error} />
        {/* WEB */}
        <Route path='/loginguru' Component={LoginGuru} />
        <Route path='/registerguru' Component={RegisterGuru} />
        <Route path='/dashboard' Component={Dashboard} />
        <Route path='/profil' Component={Profil} />

      </Routes>
      <ToastContainer />

    </>

  );
}

export default App;
