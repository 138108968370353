import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";

export default function Course() {

  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:bg-gray-800">
   Course
      <Nav menu="course" />

    </main>
  )
}