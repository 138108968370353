import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";

export default function Error() {

  return (
    <main className=" min-h-screen flex-col items-center bg-red-600 dark:bg-gray-800 text-white justify-center flex h-screen
    ">
      Silahkan install aplikasi melalui halaman utama
      <a className=" bg-white text-black p-2 mt-3 rounded-sm text-xs" href={`/`}>Kembali</a>
    </main>
  )
}