import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";

export default function Landing() {
  // window.location.reload();

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {

    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }

  return (
    <>

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap" rel="stylesheet" />
      <link href="landing/css/fontawesome-all.css" rel="stylesheet" />
      <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />
      <link href="landing/css/swiper.css" rel="stylesheet" />
      <link href="landing/css/magnific-popup.css" rel="stylesheet" />
      <link href="landing/css/styles.css" rel="stylesheet" />


      <header id="header" className="header py-28 text-center md:pt-36 lg:text-left xl:pt-16 xl:pb-32">

        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="mb-16 lg:mt-32 xl:mt-40 xl:mr-12">
            <h1 className="h1-large mb-5">Kuasai potensi Anda dengan aplikasi kursus mobile kami</h1>
            <p className="p-large mb-8 text-gray-600">Dengan pembelajaran yang mudah dan inovatif, Anda dapat meningkatkan keterampilan dan pengetahuan Anda secara efektif dan efisien.</p>
            <a onClick={onClick} className=" btn-solid-lg " href="#your-link">
              Install Aplikasi
            </a>

          </div>
          <div className="xl:text-right">
            <img className="inline" src="images/header-smartphone.png" alt="alternative" />
          </div>
        </div>
      </header>
      <div className="py-24">
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-12 lg:gap-x-12">
          <div className="lg:col-span-7">
            <div className="mb-12 lg:mb-0 xl:mr-14">
              <img className="inline" src="images/details-2.jpg" alt="alternative" />
            </div>
          </div>
          <div className="lg:col-span-5">
            <div className="xl:mt-12">
              <h2 className="mb-6">Mau belajar kapan saja, di mana saja?</h2>
              <ul className="list mb-7 space-y-2">
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>Fitur pencari kursus untuk membantu Anda menemukan kursus yang sesuai dengan minat dan kebutuhan Anda.</div>
                </li>
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>Metode pembelajaran yang mudah dan inovatif membuat kursus lebih menarik dan menyenangkan.</div>
                </li>
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>Materi kursus yang berkualitas dan relevan dengan kebutuhan Anda.</div>
                </li>
              </ul>
              <div className="flex">
                <Link to={`/login`} className="btn-solid-lg secondary mr-7" href="#your-link">Login Siswa</Link>
                <Link to={`/loginguru`} className="btn-solid-lg secondary" href="#your-link">Login Guru</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="cards-1">
        <div className="container px-4 sm:px-8 xl:px-4">

          <div className="card">
            <div className="card-image">
              <img src="images/features-icon-1.svg" alt="alternative" />
            </div>
            <div className="card-body">
              <h5 className="card-title">Platform Integration</h5>
              <p className="mb-4">You sales force can use the app on any smartphone platform without compatibility issues</p>
            </div>
          </div>

          <div className="card">
            <div className="card-image">
              <img src="images/features-icon-2.svg" alt="alternative" />
            </div>
            <div className="card-body">
              <h5 className="card-title">Easy On Resources</h5>
              <p className="mb-4">Works smoothly even on older generation hardware due to our optimization efforts</p>
            </div>
          </div>

          <div className="card">
            <div className="card-image">
              <img src="images/features-icon-3.svg" alt="alternative" />
            </div>
            <div className="card-body">
              <h5 className="card-title">Great Performance</h5>
              <p className="mb-4">Optimized code and innovative technology insure no delays and ultra-fast responsiveness</p>
            </div>
          </div>



        </div>
      </div>


      <script src="landing/js/jquery.min.js"></script>
      <script src="landing/js/jquery.easing.min.js"></script>
      <script src="landing/js/swiper.min.js"></script>
      <script src="landing/js/jquery.magnific-popup.js"></script>
      <script src="landing/js/scripts.js"></script>
    </>

  )
}