import { Link, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import Titlebar from "../components/Titlebar";
import { useState } from "react";

export default function User() {
  const navigate = useNavigate();
  const [hidePasswordForm, sethideStatus] = useState("hidden");
  const [fullname, setFullname] = useState("");
  const [avatar, setAvatar] = useState("");
  const [email, setEmail] = useState("");
  const url = `${process.env.REACT_APP_API_BASE_URL}/user/getself_user`;
  const loadData = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();

      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }

      setFullname(data.data.fullname);
      setEmail(data.data.email);
      setAvatar(process.env.REACT_APP_API_BASE_URL + "/" + data.data.avatar);
      let img = getImage(data.data.avatar);
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  const handleChange = () => {
    if (hidePasswordForm == "hidden") {
      sethideStatus("block");
    } else {
      sethideStatus("hidden");
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      navigate("/login");
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);

      const jsonData = {};
      formData.forEach((value, key) => {
        jsonData[key] = value;
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/update_user`,
        {
          method: "PUT",
          body: JSON.stringify(jsonData),
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        console.log("sukses");
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };
  const getImage = async (imgPath) => {
    try {
      const jsonData = {
        path: imgPath,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/img`,
        {
          method: "GET",
          body: JSON.stringify(jsonData),
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  loadData();

  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-white dark:bg-gray-800">
      <Titlebar menu="Profil" />
      <div
        className=" block m-auto mt-10 rounded-full bg-yellow-50"
        style={{ width: 150, height: 150 }}
      >
        {" "}
      </div>
      <p className="text-center text-xl font-semibold mt-3 dark:text-white">
        {fullname}
      </p>
      <img src={avatar} />
      <Nav menu="user" />
      <div className="m-3 mb-40">
        <form onSubmit={handleSubmit}>
          <div>
            <label className=" text-sm font-semibold dark:text-white">
              Nama Lengkap
            </label>
            <input
              type="text"
              className="block w-full  p-2 border rounded"
              onChange={(e) => setFullname(e.target.value)}
              value={fullname}
              name="fullname"
              placeholder="Masukkan Nama Lengkap"
            />
          </div>
          <div className="mt-3">
            <label className=" text-sm font-semibold dark:text-white">
              Alamat email
            </label>
            <input
              type="text"
              className="block w-full  p-2 border rounded "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Masukkan Email"
            />
          </div>
          <div className="mt-3">
            <button
              onClick={handleChange}
              className=" text-sm text-red-500 font-semibold"
            >
              Ubah Password?
            </button>
          </div>

          <div className={`mt-3 ${hidePasswordForm}`}>
            <label className={`text-sm  font-semibold`}>Password Lama</label>
            <input
              type="password"
              name="old_password"
              className="block w-full  p-2 border rounded"
              placeholder="Masukkan Password Lama"
            />
          </div>

          <div className={`mt-3 ${hidePasswordForm}`}>
            <label className={`text-sm  font-semibold`}>Password baru</label>
            <input
              type="password"
              name="password"
              className="block w-full  p-2 border rounded"
              placeholder="Masukkan Password"
            />
          </div>
          <div className={`mt-3 ${hidePasswordForm}`}>
            <label className={`text-sm  font-semibold`}>
              Konfirmasi Password baru
            </label>
            <input
              type="password"
              name="password_confirm"
              className="block w-full  p-2 border rounded"
              placeholder="Masukkan Password"
            />
          </div>

          <div className={`mt-5`}>
            <input
              type="submit"
              name="submit"
              className="block w-full  p-2 border rounded btn-orange"
              value="Simpan"
            />
          </div>
        </form>
        <div className={`mt-3 `}>
          <button
            onClick={handleLogout}
            type="submit"
            className="w-full  p-2 border  border-red-500 text-red-500 rounded-md flex justify-center items-center align-middle"
          >
            <img src="images/Logout.svg" className=" mr-2"></img>
            Keluar
          </button>
        </div>
      </div>
    </main>
  );
}
