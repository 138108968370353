import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Toast from "../../components/Toast";

export default function () {
  const navigate = useNavigate()
  const TogglePasswordButton = (props) => {
    const [imageSrc, setIconSrc] = useState('/images/eye.png');
    const [hideStatus, sethideStatus] = useState(true)
    const [passwordType, setPasswordType] = useState('password')

    const togglePassword = () => {
      sethideStatus(hideStatus == true ? false : true)
      if (hideStatus == false) {
        setIconSrc('/images/hide.png');
        setPasswordType('text');
      } else {
        setPasswordType('password');
        setIconSrc('/images/eye.png');
      }
    };

    return (
      <div className='border-b-2 w-11/12 flex'>
        <input type={passwordType} placeholder={props.placeholder} className=' h-10 focus:outline-none w-10/12 dark:bg-gray-900' name={props.name} />
        <div className='w-2/12 flex justify-end items-center'>
          <div>
            <img src={imageSrc} width={20} height={20} alt="Image" onClick={togglePassword} />
          </div>
        </div>
      </div>

    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });
    jsonData['role'] = 2
    jsonData['avatar'] = 'uploads/avatar/default.jpeg'
    const url = `${process.env.REACT_APP_API_BASE_URL}/register`
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(jsonData),
      });

      const data = await response.json()

      if (response.ok) {
        Toast('success', data.message)
        setTimeout(() => {
          navigate('/login')
        }, 2000);
      } else {
        Toast('error', data.message)
      }
    } catch (error) {
      Toast('error', 'Sistem Error')
    }
  };


  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-white dark:bg-gray-900">

      {/* logo */}
      <div className='bg-gradient-orange rounded-b-3xl p-3'>
        <div className='text-right flex justify-end'>
          <img
            className=' text-center'
            src="images/logo.png"

            alt="Logo"
          />
        </div>
        <div className=' flex justify-center px-10 py-4'>
          <img src="images/group-41.svg"

            alt=''
          />
        </div>
      </div>
      {/* form */}
      <div className=' container px-7'>
        <p className=' text-4xl font-bold pt-14 dark:text-white'>Daftar</p>
        <form className=' mt-7' onSubmit={handleSubmit}>
          <div className=' flex w-full py-2'>
            <div className='flex w-1/12 justify-center'>
              <img src="images/customer.svg"

                alt='at-sign.svg'
              />
            </div>
            <input
              type='text'
              name='fullname'
              placeholder='Nama lengkap'
              className='w-11/12 h-10 focus:outline-none border-b-2 dark:bg-gray-900' />
          </div>
          <div className=' flex w-full py-2'>
            <div className='flex w-1/12 justify-center'>
              <img src="images/at-sign.svg"
                width={30}
                height={40}
                alt='at-sign.svg'
              />
            </div>
            <input type='text' name='email' placeholder='Alamat Email' className='w-11/12 h-10 focus:outline-none border-b-2 dark:bg-gray-900' />

          </div>
          <div className=' flex w-full py-2'>
            <div className='flex w-1/12 justify-center'>
              <img src="images/lock.svg"
                width={30}
                height={42}
                alt='lock'
              />
            </div>
            <TogglePasswordButton placeholder="Password" name="password" />
          </div>
          <div className=' flex w-full py-2'>
            <div className='flex w-1/12 justify-center'>
              <img src="images/lock.svg"
                width={30}
                height={42}
                alt='lock'
              />
            </div>
            <TogglePasswordButton placeholder="Konfirmasi Password" name="password_confirm" />
          </div>

          <input type='submit' className='btn-orange mt-5' value="Daftar" />
        </form>

        <div className="fixed bottom-0 inset-x-0 flex justify-center items-end h-20 p-4">
          <p className='dark:text-white'>Sudah memiliki akun?
            <Link to='/login' className=' font-semibold'> Masuk</Link>
          </p>
        </div>
      </div>
    </main>
  )
}
