import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WebToast from "../components/WebToast";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect, useState } from "react";

export default function Profil() {
  const navigate = useNavigate();
  const [hidePasswordForm, sethideStatus] = useState("hidden");
  const [fullname, setFullname] = useState("");
  const [avatar, setAvatar] = useState("");
  const [email, setEmail] = useState("");
  const url = `${process.env.REACT_APP_API_BASE_URL}/user/getself_user`;

  const handleChange = () => {
    if (hidePasswordForm == "hidden") {
      sethideStatus("block");
    } else {
      sethideStatus("hidden");
    }
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);

      const jsonData = {};
      formData.forEach((value, key) => {
        jsonData[key] = value;
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/update_user`,
        {
          method: "PUT",
          body: JSON.stringify(jsonData),
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        console.log("sukses");
      } else {
        WebToast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const getImage = async (imgPath) => {
    // try {
    //   const jsonData = {
    //     path: imgPath
    //   };
    //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/img`, {
    //     method: 'GET',
    //     body: JSON.stringify(jsonData),
    //     credentials: "include"
    //   });
    //   const data = await response.json();
    //   if (response.status == 401) {
    //     WebToast('error', 'Session habis, silahkan login ulang')
    //     navigate('/login')
    //   } else if (response.ok) {
    //     return data
    //   }
    // } catch (error) {
    //   console.log(error);
    // };
  };
  const init = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();

      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }

      setFullname(data.data.fullname);
      setEmail(data.data.email);
      setAvatar(process.env.REACT_APP_API_BASE_URL + "/" + data.data.avatar);
      let img = getImage(data.data.avatar);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div className={`flex h-screen bg-gray-50 dark:bg-gray-900 `}>
      <Sidebar />
      <div className="flex flex-col flex-1 w-full">
        <Header title="Pengaturan Profil" />
        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Pengaturan profil
            </h2>
            <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
              <form encType="multipart/formData">
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Nama Lengkap
                  </span>
                  <input
                    type="text"
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    value={fullname}
                  />
                </label>
                <label className="block text-sm mt-4">
                  <span className="text-gray-700 dark:text-gray-400">
                    Email
                  </span>
                  <input
                    type="text"
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    value={email}
                  />
                </label>
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleChange}
                    className=" text-sm text-red-500 font-semibold"
                  >
                    Ubah Password?
                  </button>
                </div>
                <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                  <span className="text-gray-700 dark:text-gray-400">
                    Password Lama
                  </span>
                  <input
                    type="password"
                    name="old_password"
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>
                <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                  <span className="text-gray-700 dark:text-gray-400">
                    Password Baru
                  </span>
                  <input
                    type="password"
                    name="password"
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>{" "}
                <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                  <span className="text-gray-700 dark:text-gray-400">
                    Konfirmasi Password Baru
                  </span>
                  <input
                    type="password"
                    name="password_confirm"
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
