import { Link, useNavigate } from "react-router-dom"

export default function Titlebar(props) {
  const navigate = useNavigate()
  return (
    <>
      <div className=" bg-gray-900 p-3 flex ">
        <div className=" w-1/12" onClick={() => navigate(-1)} >
          <img src={`/images/Back.svg`} />
        </div>
        <div className="  -ml-3 w-11/12 text-center text-xl font-semibold dark:text-white">{props.menu}</div>
      </div >
    </>
  )
}
