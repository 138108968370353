import { Link } from "react-router-dom";
import WebToast from "../components/WebToast";
import { useNavigate } from "react-router-dom";

export default function RegisterGuru() {
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });
    jsonData['role'] = 1
    jsonData['avatar'] = 'uploads/avatar/default.jpeg'
    const url = `${process.env.REACT_APP_API_BASE_URL}/register`
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(jsonData),
      });

      const data = await response.json()

      if (response.ok) {
        WebToast('success', data.message)
        setTimeout(() => {
          navigate('/loginguru')
        }, 2000);
      } else {
        WebToast('error', data.message)
      }
    } catch (error) {
      WebToast('error', 'Sistem Error')
    }
  };

  return (
    <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div
        class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
      >
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              src="images/create-account-office.jpeg"
              alt="Office"
            />
            <img
              aria-hidden="true"
              class="hidden object-cover w-full h-full dark:block"
              src="img/create-account-office-dark.jpeg"
              alt="Office"
            />
          </div>
          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div class="w-full">
              <h1
                class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
              >
                Buat Akun Guru
              </h1>
              <form onSubmit={handleSubmit}>
                <label class="block text-sm">
                  <span class="text-gray-700 dark:text-gray-400">Nama Lengkap</span>
                  <input
                    class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="Masukkan Nama Lengkap" name='fullname'
                  />
                </label>
                <label class="block text-sm mt-4">
                  <span class="text-gray-700 dark:text-gray-400">Email</span>
                  <input
                    class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="Masukkan Email" name='email'
                  />
                </label>
                <label
                  class="block mt-4 text-sm">
                  <span class="text-gray-700 dark:text-gray-400">Password</span>
                  <input
                    class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="***************"
                    type="password" name="password"
                  />
                </label>
                <label class="block mt-4 text-sm">
                  <span class="text-gray-700 dark:text-gray-400">
                    Konfirmasi password
                  </span>
                  <input
                    class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="***************"
                    type="password" name="password_confirm"
                  />
                </label>
                <button type="submit"
                  class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-red"

                >
                  Buat Akun
                </button>
              </form>

              <hr class="my-8" />



              <p class="mt-4">
                <Link to={`/loginguru`}
                  class="text-sm font-medium text-red-600 dark:text-red-400 hover:underline"

                >
                  Sudah memiliki akun? Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

