import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";

export default function Home() {

  const CourseComponent = (props) => {
    return (
      <>
        <div className='flex bg-white rounded-xl mt-3 dark:bg-gray-800 p-4'>
          <div className='w-3/12 flex items-center'>Logo</div>
          <div className='w-9/12 0'>
            <span className='text-xs text-gray-400'>{props.teacher}</span>
            <br></br>
            <span className=' font-semibold'>{props.title} </span>
            <br></br>
            <div className='text-xs text-right mt-3'>Target tercapai</div>
            <div id="progress-bar" className="w-full h-4 bg-gray-300 rounded-full">
              <div id="progress" className="h-full text-center text-white bg-red-500 rounded-full text-xs" style={{
                width: props.target
                  + '%'
              }} >{props.target}%</div>
            </div>
          </div>
        </div >
      </>
    )
  }


  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:bg-gray-800 dark:text-white">
      <div className='text-right flex justify-end p-3 bg-white dark:bg-gray-800'>
        <img
          className=' text-center'
          src="/images/logo-red.png"
          width={51}
          height={19}
          alt="Logo"
        />
      </div>
      <div className=' container px-3 pb-5 rounded-b-3xl bg-white dark:bg-gray-800'>
        <div className='text-xl font-semibold'>
          Halo, Nama User
        </div>
        <div className=' text-gray-400 text-base'>Mau Belajar Apa Hari Ini?
        </div>
        <div className=' flex mt-4 '>
          <div className=' bg-red-100 w-5/12 h-28 rounded-lg text-red-700 flex '>
            <div className=' w-1/2'>
              <div className=' absolute -mt-4 -ml-3'>
                <img src="/images/Bookmark.png" width={96} height={96} alt='' />
              </div>
            </div>
            <div className='items-center'>
              <div className=' text-red-500 font-bold text-4xl mt-5 text-center'>120</div>
              <div className=' text-red-500 text-center'>Kursus</div>
            </div>
          </div>
          <div className=' w-6/12 ml-2 card-gradient-red flex p-3 items-center'>
            <div className='  text-white font-semibold text-xl 
            '>Temukan Kursus</div>
            <div className=' w-auto'>
              <img src="/images/Literature.svg" width={100} height={100} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='px-3 mt-3 font-semibold text-base'>Kursus Saya</div>
      <div className='px-3 mt-3'>
        <CourseComponent teacher="Prof Sutikno Aryo Nugroho" title="Pengantar Ilmu Biologi Dasar" target="56" />
        <CourseComponent teacher="Prof Sutikno Aryo Nugroho" title="Pengantar Ilmu Biologi Dasar" target="46" />
        <CourseComponent teacher="Prof Sutikno Aryo Nugroho" title="Pengantar Ilmu Biologi Dasar" target="20" />
        <CourseComponent teacher="Prof Sutikno Aryo Nugroho" title="Pengantar Ilmu Biologi Dasar" target="16" />
        <CourseComponent teacher="Prof Sutikno Aryo Nugroho" title="Pengantar Ilmu Biologi Dasar" target="96" />
      </div>
      <Nav menu="home" />

    </main>
  )
}