import { Link } from "react-router-dom"
export default function Nav(props) {
  const getNavColor = (menu) => {
    if (menu == props.menu) {
      return 'c';
    } else {
      return 'g';
    }
  }
  return (
    <>
      <div className=' h-20'></div>
      <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white">
        <div className="container mx-auto flex justify-center bg-white dark:bg-gray-700 items-center h-16">
          <nav className="space-x-12 text-black flex">
            <Link to='/home'><img src={`/images/${getNavColor('home')}_Home.svg`} width={30} height={30} alt='' />
            </Link>
            <Link to={`/course`} className="">
              <img src={`/images/${getNavColor('course')}_Education.svg`} width={30} height={30} alt='' />
            </Link>
            <Link to="/explore" className="">
              <img src={`/images/${getNavColor('explore')}_Compass.svg`} width={30} height={30} alt='' />
            </Link>
            <Link to='/home'><img src={`/images/${getNavColor('potential')}_Favorites.svg`} width={30} height={30} alt='' />
            </Link>
            <Link to='/user'><img src={`/images/${getNavColor('user')}_Customer.svg`} width={30} height={30} alt='' />
            </Link>

          </nav>
        </div>
      </div>
    </>
  )
}
